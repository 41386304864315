import React from 'react'
import { Link, graphql } from 'gatsby'
import classNames from 'classnames'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Layout from 'layout/layout'
import HouseItem from 'components/HouseItem/HouseItem'
import SectionPricing from 'pages-sections/construction/SectionPricing'
import style from 'assets/jss/styles/components/houseStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import BreadcrumbsComponent from 'components/Breadcrumbs/BreadcrumbsComponent'
import Img from 'gatsby-image';
import { CONTENEDORES, CONSTRUCCION } from 'constants/routes';

const useStyles = makeStyles(style)

const ContainerHouses = ({ data, pageContext }) => {
  const classes = useStyles()
  const obj = data.contenedoresJson.houses.find(house => house.slug === pageContext.slug);
  
  return (
    <Layout
      pageTitle={obj.title}
      pageDescription={obj.description}
      headerImageGatsby={obj.images[0].childImageSharp.fluid}
      bgImgStyle={{backgroundPositionY: "top"}}
      filter="transparent"
      small={false}
    >
      <BreadcrumbsComponent
        links={[
            {link: CONSTRUCCION, text: "Construcción de viviendas"},
            {link: CONTENEDORES, text: "Construcción con contenedores"}
        ]}
        presentPath={obj.slug.replace(/-/g, " ")}
      />

      <h1 className={classes.title}>{obj.title}</h1>
      <h2 className={classes.subtitle}>{obj.description}</h2>

      <GridContainer justify="center">
        <GridItem xs={12} className={classes.plano}>
            <Img
              fluid={obj.plan.childImageSharp.fluid}
              alt="Plano de la casa"
              title="Plano de la casa"
              className="slick-image"
            />
        </GridItem>              
      </GridContainer>

      <HouseItem 
        house={obj}
      />
 
      <SectionPricing house={obj} />
          
    </Layout>
  )
}

export default ContainerHouses

export const query = graphql`
  query HouseQuery($slug: String) {
    contenedoresJson(houses: {elemMatch: {slug: {eq: $slug}}}) {
      houses {
        title
        description
        text
        alt
        sup
        distribution
        price
        price2
        price3
        plan {
          childImageSharp {
            fluid(maxHeight: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        slug
        images {
          childImageSharp {
            fluid(maxHeight: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;